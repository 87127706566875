<template>
  <v-row>
    <template v-if="processos && processos.length == 0">
      <v-col cols="12" sm="6" md="4">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-col>
    </template>
    <template
      v-else-if="
        processos &&
        processos.data &&
        processos.data.length == 1 &&
        processos.data[0].uuid == null
      "
    >
      <v-col cols="12">
        <h2 class="text-h6 text-center">
          Você não está em nenhum processo seletivo.
        </h2>
      </v-col>
    </template>
    <template v-else>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="(item, i) in processos.data"
        :key="item.id"
      >
        <v-card>
          <div class="d-flex flex-row align-center mx-2">
            <v-card-title class="text-h6 font-weight-bold pl-3 pb-1">
              {{ item.titulo }}
            </v-card-title>
            <v-spacer></v-spacer>
          </div>

          <v-card-text>
            <p class="pa-0 ma-0 text-truncate">
              {{ item.descricao }}
            </p>
          </v-card-text>

          <v-card-text class="d-flex justify-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  :color="status[item.status].color"
                >
                  {{ status[item.status].text }}
                </v-chip>
              </template>
              <span>Status</span>
            </v-tooltip>
          </v-card-text>

          <v-card-text class="my-3">
            <div
              class="d-flex flex-row align-center justify-space-between flex-wrap"
            >
              <p class="ma-0 pa-0">
                Processos: {{ item.progresso.progresso_atual }}/{{
                  item.progresso.progresso_maximo
                }}
              </p>

              <p class="ma-0 pa-0">
                {{ resultPercentProcesso(item) }}% Completo
              </p>
            </div>
            <v-progress-linear
              :value="resultPercentProcesso(item)"
            ></v-progress-linear>
          </v-card-text>

          <v-card-actions v-if="item.status_processo == 'CAN' ? true : false">
            <v-alert
              dark
              dense
              min-width="100%"
              icon="mdi-alert-circle"
              color="error darken-4"
            >
              Processo Cancelado
            </v-alert>
          </v-card-actions>
          <v-card-actions
            v-else
            class="d-flex flex-column align-center justify-center"
          >
            <v-btn
              small
              class="my-1"
              width="100%"
              @click="toView(item)"
              :disabled="item.status == 'FIN' ? true : false"
              color="info"
            >
              <v-icon class="mr-2">mdi-eye</v-icon>
              Visualizar
            </v-btn>
            <v-btn
              small
              class="mx-0 my-1"
              width="100%"
              @click="cancel(item, true)"
              :disabled="validateStatus(item)"
              outlined
              color="error"
            >
              <v-icon class="mr-2">mdi-close</v-icon>
              Cancelar processo
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>

    <!-- Modal de confirmar cancelamento -->
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Cancelamento do processo seletivo
        </v-card-title>
        <v-card-text>
          Você está preste a realizar uma ação sem reversão. Ao confirmar o
          cancelamento, você será automaticamente retirado do processo seletivo
          para a vaga de <strong>{{ item.titulo }}</strong
          >.
          <br />
          <br />
          Deseja confirmar está operação?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="dialog = false">
            Fechar
          </v-btn>
          <v-btn
            :loading="loading"
            color="error darken-1"
            text
            @click="cancel()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "CardProcessos",
  data() {
    return {
      status: {
        FIN: { text: "Finalizado", color: "warning" },
        PEN: { text: "Pendente", color: "secondary" },
        PUB: { text: "Publicado", color: "success" },
        REP: { text: "Reprovado", color: "error" },
      },

      dialog: false,
      loading: false,
      item: {},
    };
  },
  methods: {
    /**
     * @descripion Visualizar a vaga
     */
    toView(item) {
      this.$router.push({ name: "res-processo", params: { uuid: item.uuid } });
    },
    /**
     * @description Cancela a vaga
     */
    cancel(item, show = false) {
      if (show) {
        this.item = item;
        this.dialog = true;

        return;
      }

      this.loading = true;

      const data = {
        url: `res-processo-candidato/${this.item.uuid}`,
        data: {
          status: "CAN",
          _method: "PUT",
        },
      };

      this.$store
        .dispatch("resProcessosCandidatos/save", data)
        .then(() => {
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "success",
            timeout: 3000,
            text: "Processo seletivo cancelado com sucesso.",
          });

          const index = this.processos.data.findIndex(
            (el) => (el.uuid = this.item.uuid)
          );

          this.processos.data[index].status_processo = "CAN";
        })
        .finally(() => {
          this.loading = false;
          this.dialog = false;
          this.item = {};
        });
    },
    resultPercentProcesso(item) {
      if (
        item.progresso &&
        !item.progresso.progresso_atual &&
        !item.progresso.progresso_maximo
      )
        return 0;

      const result =
        (item.progresso.progresso_atual / item.progresso.progresso_maximo) *
        100;

      return result;
    },
    validateStatus(item) {
      if (item.status == "FIN") return true;
      if (item.status_processo == "RPRNE") return true;
      if (item.status_processo == "CAN") return true;
    },
  },
  computed: {
    processos: {
      get() {
        return this.$store.getters["resCandidatos/getProcessos"];
      },
    },
  },
};
</script>
